import axios, { AxiosStatic, AxiosResponse, AxiosError, AxiosRequestConfig, CancelTokenSource } from 'axios';

import { NewCardRequest, TokenRequest, CompanySearchRequest, ILiveness } from '../models/api/request';
import { UserDetailInformation, CardInformation } from '../models/context';
import {
  BinValidationCard,
  CardBinValidationResponse,
  CvvResponse,
  ResponseGetTimeStamp,
  UserDetailInformationResponseAndRequest,
  CardResponse,
  CardPaymentResponse,
  DocumentScanRequest,
  DocumentScanResponse,
} from '../models/api/response';

import env from 'env';

import {
  convertBinValidationToApp,
  convertCardInfoToApp,
  convertCardPaymentInfoToApp,
  convertCompanySearchToApp,
  convertCompanyToApp,
  convertCustomerToApp,
  convertCvuToApp,
  convertCvvToApp,
  converterGetTimeStamp,
  convertSecurityToApp,
  convertUserDetailToApp,
  convertUserDNIToApp,
} from '../utils/converts';
import {getTokenExUrl} from "@/utils/tokenex";

export type SiteLockParam = 'lock_account' | 'unlock_account';
export type SiteLockErrorCode = 'FGS1304' | 'default' | null;

interface MemoBinValidationCard {
  [key: string]: BinValidationCard;
}

const {
  COMPANY_SEARCH,
  GET_BIN_VALIDATION,
  GET_CUSTOMER,
  GET_CVU,
  GET_DETAIL_USER,
  GET_INFO_CARD,
  GET_PAYMENT,
  GET_TOKEN_LOCK,
  GET_DYNAMIC_USER_FORM,
  NEW_CARD,
  PATCH_CUSTOMER,
  PATCH_CVU,
  PATCH_LOCK,
  PATH_DETAIL_USER,
  PATCH_ENTER_PIN,
  PATCH_CREATE_PIN,
  PATCH_DYNAMIC_USER_FORM,
  POST_PAYMENT,
  TIMEOUT_REQUEST,
  TIMESTAMP,
  WEBAUTHN_REGISTRATION_START,
  WEBAUTHN_REGISTRATION_FINISH,
  WEBAUTHN_AUTHENTICATION_START,
  WEBAUTHN_AUTHENTICATION_FINISH,
  SCAN_DOCUMENT,
  POST_LIVENESS,
  RESET_PIN,
  RESEND_EMAIL_CODE,
} = env;

class CredictCardService {
  private http: AxiosStatic;
  private previousBinValidationRequest: CancelTokenSource | undefined;
  private resultsCards: MemoBinValidationCard = {};
  private searchResults: any = {};
  private previousSearchRequest: CancelTokenSource | undefined;

  constructor() {
    this.http = this.createAxiosInterceptor();
  }

  createAxiosInterceptor() {
    axios.interceptors.request.use(this.handleRequest, this.handleError);
    axios.interceptors.response.use(this.handleResponse, this.handleError);
    axios.defaults.headers = {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    };
    return axios;
  }

  handleRequest(config: AxiosRequestConfig) {
    return {
      ...config,
      timeout: TIMEOUT_REQUEST,
    };
  }

  /**
   * We are capturing every response to see if it contains a
   * "communication_media" object, so that we can infer and store,
   * among other things, the client phone number.
   * 
   * This will be persisted in the browser for later use, to indicate
   * the number to use for returning to WhatsApp, when the user
   * continues with the intended flow.
   * 
   * @param response 
   * @returns 
   */
  handleResponse(response: AxiosResponse) {
    if (response?.data?.communication_media) {
      const { client_id, platform, provider } = response.data.communication_media;
      localStorage.setItem('pagochat-communication-media', JSON.stringify({
        client_id,
        platform,
        provider,
      }));
    }
    return response;
  }

  handleError(error: AxiosError) {
    throw error;
  }

  async getTimeStamp(): Promise<string | { status: number; error: string }> {
    try {
      return await this.http
        .get(TIMESTAMP)
        .then<ResponseGetTimeStamp>(({ data }) => data)
        .then(converterGetTimeStamp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async getCardInfo({ token }: TokenRequest) {
    try {
      return await this.http
        .get(`${GET_INFO_CARD}/${token}`)
        .then<CardResponse>(({ data }) => data)
        .then<CardInformation>(convertCardInfoToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async getCardPaymentInfo({ token }: TokenRequest) {
    try {
      return await this.http
        .get(`${GET_PAYMENT}/${token}`)
        .then<CardPaymentResponse>(({ data }) => data)
        .then<CardInformation>(convertCardPaymentInfoToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async userNewCard(body: NewCardRequest, { token }: TokenRequest, payable = false) {
    try {
      const cardData = payable ? { form_data: { ...body } } : { ...body };
      return await this.http[payable ? 'patch' : 'post'](payable ? POST_PAYMENT : NEW_CARD, { ...cardData }, { params: { token } }).then(
        ({ data }) => data
      );
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      if (status === 409) {
        const { error_code: errorCode } = response.data?.details?.[0] ?? {};
        throw { status, error: message, errorCode };
      }
      throw { status, error: message, details: response.data };
    }
  }

  async getUserDetail({ token }: TokenRequest) {
    try {
      return await this.http
        .get(`${GET_DETAIL_USER}/${token}`)
        .then<UserDetailInformationResponseAndRequest>(({ data }) => data)
        .then<UserDetailInformation>(convertUserDetailToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async updateUserDetail(userDetail: UserDetailInformationResponseAndRequest, token: string) {
    try {
      return await this.http.patch(PATH_DETAIL_USER, { ...userDetail }, { params: { token } }).then(({ data }) => data);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      const { error_code: errorCode } = response.data?.details?.[0] ?? {};
      throw { status, error: message, errorCode };
    }
  }

  async getBinValidation(cardNumber: string, token: string): Promise<BinValidationCard> {
    try {
      const firstSixCardNumbers = cardNumber.slice(0, 6);

      if (this.resultsCards[firstSixCardNumbers]) {
        return this.resultsCards[firstSixCardNumbers];
      }

      if (this.previousBinValidationRequest) {
        this.previousBinValidationRequest.cancel('Cancel previous request');
      }

      const CancelToken = this.http.CancelToken;
      this.previousBinValidationRequest = CancelToken.source();
      const result = await this.http
        .get(GET_BIN_VALIDATION, {
          params: {
            token,
            first_six: firstSixCardNumbers,
          },
          cancelToken: this.previousBinValidationRequest.token,
        })
        .then<CardBinValidationResponse>(({ data }) => data)
        .then(convertBinValidationToApp);

      this.resultsCards[firstSixCardNumbers] = result;
      return result;
    } catch ({ response, message }) {
      throw { error: message };
    }
  }

  async getCvvinformation({ token }: TokenRequest) {
    try {
      console.log('JRR')
      return await this.http
        .get(`${GET_PAYMENT}/${token}`)
        .then<CvvResponse>(({ data }) => data)
        .then(convertCvvToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async postCvvInformation(cvv: string, cardId: string, { token }: TokenRequest) {
    try {
      return await this.http
        .patch(POST_PAYMENT, { form_data: { card: { uid: cardId, security_code: cvv } } }, { params: { token } })
        .then(({ data }) => data);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      throw { status, error: message };
    }
  }

  async postToTokenizer(cardNumber: string, cardNumberExpirationMonth: string, cardNumberExpirationYear: string, oneTimeAuthorization: string ) {
    try {
      let path = getTokenExUrl();
      console.log(`path token: ${path}`);

        return await this.http
          .post(getTokenExUrl(), { data: { cardNumber: cardNumber,
                                           cardNumberExpirationMonth: cardNumberExpirationMonth,
                                           cardNumberExpirationYear: cardNumberExpirationYear
          }, expireTimeSeconds: "-1",
            oneTimeAuthorization: oneTimeAuthorization })
          .then(({ data }) => data);
            
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      throw { status, error: message };
    }
  }

  async getCvuInformation({ token }: TokenRequest) {
    try {
      return await this.http
        .get(`${GET_CVU}/${token}`)
        .then(({ data }) => data)
        .then(convertCvuToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async postCvuInformation(cvu: any, token: string) {
    try {
      return await this.http.patch(PATCH_CVU, { ...cvu }, { params: { token } }).then(({ data }) => data);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      throw { status, error: message };
    }
  }

  async getCompanySearch({ token, search, next, categoryName }: CompanySearchRequest) {
    const parseSearch = encodeURI(search);
    const parseCategorySearch = categoryName ? encodeURI(categoryName) : undefined;

    const pageRef = `$$PagoChat_CompanyPage_${(categoryName ?? '')
      .toLocaleLowerCase()
      .replace(' ', '')
      .trim()}_${parseSearch.toLocaleLowerCase().replace(' ', '').trim()}`;

    if (this.searchResults[search] && !next) {
      return {
        companies: this.searchResults[search],
        last: this.searchResults[`${pageRef}_lastPage`],
      };
    }

    if (this.previousSearchRequest) {
      this.previousSearchRequest.cancel('Cancel previous request');
    }

    const params = {
      length: 15,
      page: this.searchResults[pageRef] ?? 1,
      category: parseCategorySearch,
    };

    const CancelToken = this.http.CancelToken;
    this.previousSearchRequest = CancelToken.source();

    try {
      const { last, companies, communicationToken } = await this.http
        .get(`${COMPANY_SEARCH}/${token}`, {
          params: { company_name: parseSearch, ...params },
          cancelToken: this.previousSearchRequest.token,
        })
        .then(({ data }) => data)
        .then(convertCompanySearchToApp);

      this.searchResults[pageRef] = params.page;
      this.searchResults[search] = [...(this.searchResults[search] ?? []), ...companies];
      this.searchResults[pageRef] = (this.searchResults[pageRef] ?? 1) + 1;
      this.searchResults[`${pageRef}_lastPage`] = last ?? false;

      return {
        companies: this.searchResults[search],
        last: this.searchResults[`${pageRef}_lastPage`],
        communicationToken
      };
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      throw { status, error: message };
    }
  }

  cleanSearchResult = () => this.searchResults = {};

  async getCustomer({ token }: TokenRequest) {
    try {
      return await this.http
        .get(`${GET_CUSTOMER}/${token}`)
        .then(({ data }) => data)
        .then(convertCustomerToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      return { status, error: message };
    }
  }

  async patchCustomer(customer: any, token: string) {
    try {
      return await this.http.patch(PATCH_CUSTOMER, { ...customer }, { params: { token } }).then(({ data }) => data);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      throw { status, error: message };
    }
  }

  async postDocument({ documentFront, documentBack }: DocumentScanRequest, { token }: TokenRequest): Promise<any> {
    try {
      const formData = new FormData();
      formData.append('document_front', documentFront);
      formData.append('document_back', documentBack);
      return await this.http
        .post(`${SCAN_DOCUMENT}/${token}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then<DocumentScanResponse>(({ data }) => data)
    } catch ({ response, message, status }) {
      const error = response?.data.details ? response.data.details[0]?.error_message : response.data.error;
      const errorCode = response?.data.details && response.data.details[0]?.error_code;
      throw { status, error, response, message, errorCode };
    }
  }

  async getTokenLock(token: string) {
    try {
      return await this.http
        .get(`${GET_TOKEN_LOCK}/${token}`)
        .then(({ data }) => data)
        .then(convertSecurityToApp);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 400 };
      throw { status, error: message };
    }
  }

  async patchLock(pin: any, token: string) {
    try {
      return await this.http.patch(PATCH_LOCK, { ...pin }, { params: { token } }).then(({ data }) => data);
    } catch ({ response, message }) {
      const { status } = response ?? { status: 500 };
      const { error_code: errorCode } = response.data?.details?.[0] ?? {};
      throw { status, error: message, errorCode, details: response.data?.details };
    }
  }

  async patchSiteLockAccount(cell_phone: string, pin: string, command: SiteLockParam) {
    try {
      await this.http.patch(PATCH_LOCK, { cell_phone, pin }, { params: { command } });
    } catch ({ response }) {
      throw { code: response?.data?.details?.[0]?.error_code ?? 'default' };
    }
  }

  /**
   * @desc: Al no ser un servicio requerido, en el catch se fuerza a retornar el objeto por default
   */
  async getCompanyDetails() {
  }
  async patchEnterPin(token: string, pin: string, recaptchaToken: any, isWebAuthn: boolean) {
    let form_data: any = {};
    if (isWebAuthn) {
      form_data.web_authentication = {
        is_web_authn: true
      }
    } else {
      form_data.pin = {
        value: pin,
        recaptcha: {
          response: recaptchaToken
        }
      }
    }
    try {
      const { data } = await this.http
        .patch(
          PATCH_ENTER_PIN,
          {
            form_data
          },
          {
            params: {
              token
            }
          }
        )
      return data;
    } catch ({ response }) {
      if (response?.data?.details?.length > 0) {
        throw new Error(response?.data?.details[0]?.error_code);
      } else {
        throw new Error(response?.status);
      }
    }
  }

  /**
   * Generate PIN
   * @param {string} token - REDIS Token
   * @param {string} pin  - the new PIN
   * @param {string} confirm  - the confirm of the new PIN
   */
  async patchGeneratePin(token: string, pin: string, confirm: string) {
    return this.http.patch(PATCH_ENTER_PIN, { data: { pin, confirm } }, { params: { token } }).then(({ data }) => data);
  }

  async getDynamicUserData<T>(token: string): Promise<T> {
    return this.http.get(`${GET_DYNAMIC_USER_FORM}/${token}`).then(({ data }) => data);
  }

  async patchDynamicUserData<T>(token: string, data: any, isUpdatePin: boolean): Promise<T> {
    if(isUpdatePin){
        return this.http.patch(PATCH_CREATE_PIN, data, { params: { token } }).then(({ data }) => data);
    }else{
        return this.http.patch(PATCH_DYNAMIC_USER_FORM, data, { params: { token } }).then(({ data }) => data);
    }
  }

  async postWebAuthnRegistrationStart<T>(token: string): Promise<T> {
    return this.http.post(`${WEBAUTHN_REGISTRATION_START}/${token}`).then(({ data }) => data);
  }

  async postWebAuthnRegistrationFinish<T>(token: string, data: any): Promise<T> {
    return this.http.post(`${WEBAUTHN_REGISTRATION_FINISH}/${token}`, {
      data
    }).then(({ data }) => data);
  }

  async postWebAuthnAuthenticationStart<T>(token: string): Promise<T> {
    return this.http.post(`${WEBAUTHN_AUTHENTICATION_START}/${token}`).then(({ data }) => data);
  }

  async postWebAuthnAuthenticationFinish<T>(token: string, data: any): Promise<T> {
    return this.http.post(`${WEBAUTHN_AUTHENTICATION_FINISH}/${token}`, {
      data
    }).then(({ data }) => data);
  }

  async postLiveness(data: ILiveness, token: string): Promise<any> {
    try {
      return await this.http
        .post(
          `${POST_LIVENESS}`,
          data,
          {
            params: {
              token,
            },
            headers: { 'Content-Type': 'application/json' },
          }
        )
    } catch ({ response, message, status }) {
      const error = response?.data.details ? response.data.details[0]?.error_message : response.data.error;
      const errorCode = response?.data.details && response.data.details[0]?.error_code;
      throw { status, error, response, message, errorCode };
    }
  }

  async resetPin<T>(token: string): Promise<T> {
    return this.http.post(RESET_PIN, { token });
  }

  async resendEmailCode<T>(token: string): Promise<T> {
    return this.http.post(RESEND_EMAIL_CODE, { token });
  }
}

export default new CredictCardService();