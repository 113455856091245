import { ValidateParams } from '../models/tokenex';

const TOKENEX_API_URL_NON_PROD = 'https://api.waasabi.io/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_QA = '/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_DEV = '/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_DEMO = '/tokenizer/api/v1/tokenize';
const TOKENEX_API_URL_PROD = 'https://api.waasabi.io/tokenizer/api/v1/tokenize';

const validCardTokenex = ({ isValid, isCvvValid }: ValidateParams) => {
  if (isValid && isCvvValid) {
    return true;
  }

  return false;
};

const getTokenExUrl = () => {
  const host = typeof window !== 'undefined' && (window as any)?.location.host;
  
  return host === 'chatbot.silbo.money'
    ? TOKENEX_API_URL_PROD
    : host === 'qa-aws.waasabi.io'
    ? TOKENEX_API_URL_QA
    : host === 'dev-aws.waasabi.io'
    ? TOKENEX_API_URL_DEV
    : host === '318455-demo.waasabi.io'
    ? TOKENEX_API_URL_DEMO
    : TOKENEX_API_URL_NON_PROD;
}

export { validCardTokenex, getTokenExUrl };